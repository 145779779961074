import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingMobileApp from "../../components/header/landing/HeaderLandingMobileApp";
import CopyRight from "../../components/footer/CopyRight";

const MobileAppLanding = () => {
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title>
          {" "}
          Ptchk Apps
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingMobileApp />
      {/* End Mobile App Landing Header */}

      {/* =============================================
            Theme Hero Banner
        ==============================================  */}
      <div className="hero-banner-ten">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-11 col-md-8 m-auto">
              <h1
                className="hero-heading"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                Building apps like no one before.
              </h1>
              <p
                className="hero-sub-heading"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                Mobile app developer paving the way to the future.
              </p>
            </div>
          </div>
          {/* End .row */}

          <div className="d-sm-flex align-items-center justify-content-center button-group">
            <a
              href="https://apps.apple.com/developer/id1650198320"
              className="d-flex align-items-center"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <img src="images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="icon" className="icon-button" />
            </a>
          </div>
        </div>
        {/* End .container */}
      </div>
      {/* /.hero-banner-ten */}


      {/* 	=============================================
            Fancy Feature Twenty Five
        ==============================================  */}
      <div
        className="fancy-feature-twentyFive lg-container pt-200 md-pt-100"
        id="features"
      >
        <div className="container">
          <div className="block-style-twentyFive">
            <div className="row align-items-center">
              <div
                className="col-xl-7 col-lg-6 col-md-10 m-auto"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="screen-container">
                  <img src="images/screen_25.png" alt="screen" />
                </div>
                {/*  /.screen-container */}
              </div>
              <div
                className="col-xl-5 col-lg-6"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper pl-xl-5">
                  <h6>
                    Over <span>1,000,000 users</span>
                  </h6>
                  <h3 className="title">Award winning applications.</h3>
                  <p>
                    We create our own tech products, used and loved by millions all around the world.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyFive */}

          <div className="block-style-twentyFive mt-200 md-mt-100">
            <div className="row align-items-center">
              <div
                className="col-xl-7 col-lg-6 col-md-10 m-auto text-center text-lg-right order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="screen-container">
                  <img
                    src="images/shape/bg6.svg"
                    alt="shape"
                    className="ml-auto bg-round-shape"
                  />
                  <div className="block-content">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <div className="feature-meta">
                          <div className="icon d-flex align-items-end">
                            <img src="images/icon/136.svg" alt="" />
                          </div>
                          <h4>
                            Unique Technologies
                          </h4>
                        </div>
                        {/* /.feature-meta */}
                      </div>
                      {/* End .col */}

                      <div className="col-sm-6">
                        <div className="feature-meta">
                          <div className="icon d-flex align-items-end">
                            <img src="images/icon/137.svg" alt="" />
                          </div>
                          <h4>
                            Top <br/> Experts
                          </h4>
                        </div>
                        {/* /.feature-meta */}
                        <div className="feature-meta">
                          <div className="icon d-flex align-items-end">
                            <img src="images/icon/138.svg" alt="" />
                          </div>
                          <h4>
                            Huge <br/> Impact
                          </h4>
                        </div>
                        {/* /.feature-meta */}
                      </div>
                      {/* End .col */}
                    </div>
                  </div>
                  {/*  /.block-content */}
                </div>
                {/* /.screen-container */}
              </div>
              <div
                className="col-xl-5 col-lg-6"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper order-lg-first">
                  <h6>
                    <span>Best Team</span>
                  </h6>
                  <h3 className="title">Want to join our team?</h3>
                  <p>We are a passionate team of skilled professionals that design, develop and support innovative mobile apps.</p>
                </div>
                {/*  /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyFive */}
        </div>
      </div>
      {/*  /.fancy-feature-twentyFive */}

      {/* =====================================================
            Fancy Short Banner Twelve
        ===================================================== */}
      <div className="fancy-short-banner-twelve mt-200 md-mt-130">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-10 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-ten text-center">
                <h2>Love our apps? Download now!</h2>
                <p className="pt-25 pb-45">
                  Try it risk free — we don’t charge any cancellation fees
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
            <div className="d-sm-flex align-items-center justify-content-center button-group">
              <a href="https://apps.apple.com/developer/id1650198320" className="d-flex align-items-center ios-button">
                <img
                  src="images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg"
                  alt="icon"
                  className="icon-button"
                />
              </a>
            </div>
          </div>
        </div>
        {/* /.container */}
        <img
          src="images/shape/220.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/221.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/* /.fancy-short-banner-twelve */}

      {/* =====================================================
				Footer Style Seven
			===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container">
            <div className="bottom-footer">
              <CopyRight />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default MobileAppLanding;
